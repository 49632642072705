<template>
  <div>
    <span v-if="overline">{{ overline }}</span>
    <h3 v-if="heading">{{ heading }}</h3>
    <p v-if="body">{{ body }}</p>
  </div>
</template>

<script>
export default {
  name: 'OrderInfoItem',
  props: {
    overline: String,
    heading: String,
    body: String,
  },
};
</script>

<style scoped>
span {
  display: block;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 4px;
}

h3 {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 4px;
}

p {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.6);
}
span + p {
  color: rgba(0, 0, 0, 0.87);
}
</style>
