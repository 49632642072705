<template>
  <div id="app">
    <OrderInfo />
  </div>
</template>

<script>
import OrderInfo from './components/OrderInfo.vue';

export default {
  name: 'app',
  components: {
    OrderInfo,
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#app {
  height: 100%;
}

body {
  background: #fafafa;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
input,
button {
  font-family: Roboto, sans-serif;
  font-weight: 400;
}
</style>
