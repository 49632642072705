<template>
  <div>
    <div v-if="info.not_found">
      <div class="card not-found">
        <span class="heading-2">Não encontramos seu pedido :(</span>
      </div>
    </div>
    <div class="card" v-else-if="Object.keys(info).length">
      <div class="header">
        <h1 class="heading-1">Pedido #{{ info.nro_pedido }}</h1>
        <p class="body-1 out-space-top-x-small">
          {{ info.status_descr }}, Realizado em
          {{ info.data_emissao }}
        </p>
      </div>

      <div>
        <a
          v-if="info.link_danfe"
          :href="info.link_danfe"
          target="_blank"
          class="btn"
        >
          Nota fiscal em .PDF
        </a>
        <a
          v-if="info.link_xml"
          :href="info.link_xml"
          target="_blank"
          class="btn"
        >
          Nota fiscal em .XML
        </a>

        

        <a
          v-if="info.link_boleto"
          :href="info.link_boleto"
          target="_blank"
          class="btn"
        >
          Boletos
        </a>
      </div>

      <div v-if="info.acompanhamento">
        <div class="side-dish" v-if="info.acompanhamento.length > 0">
          <div
            :class="[
              'side-dish-item',
              {
                complete: item.processo_status === 1,
                active: item.processo_status === 0,
                error: item.processo_status === 2,
              },
            ]"
            v-for="(item, index) in info.acompanhamento"
            :key="index"
          >
            <span class="body-2">{{
              item.situacao ? item.situacao : item.tipo_descr
            }}</span>
            <span class="caption" v-if="item.data_hora">
              {{ item.date }}
            </span>
            <span class="caption visible-block" v-if="item.data_hora">
              {{ item.time }}
            </span>
          </div>
        </div>
      </div>

      <div class="table out-space-top-large">
        <div class="table-header">
          <span>Código</span>
          <span>Produto</span>
          <span>Qtd.</span>
          <span>Preço</span>
          <span>Total</span>
        </div>
        <div
          class="table-row"
          v-for="(item, index) in info.itens"
          :key="item.id"
        >
          <span class="body-2">{{ item.id }}</span>
          <span class="body-2">{{ item.produto }}</span>
          <span class="body-2">{{ item.quantidade }}</span>
          <span class="body-2">{{ item.valor_unitario | money }}</span>
          <span class="body-2">{{ item.valor_total | money }}</span>
          <span @click="handleSnackbar(index)" style="cursor:pointer">
            <img src="../assets/icon-info.svg" />
            <div class="snackbar-mask" v-show="showSnackbar === index"></div>
            <div class="snackbar" v-show="showSnackbar === index">
              <div>
                <span class="body-2">{{ item.produto }}</span>
              </div>
              <div>
                <span class="body-2">Frete</span>
                <span class="body-2">{{ item.valor_frete | money }}</span>
              </div>
              <div>
                <span class="body-2">Seguro</span>
                <span class="body-2">{{ item.valor_seguro | money }}</span>
              </div>
              <div>
                <span class="body-2">Acrescimo</span>
                <span class="body-2">{{ item.valor_acrescimo | money }}</span>
              </div>
              <div>
                <span class="body-2">Desconto</span>
                <span class="body-2">{{ item.valor_desconto | money }}</span>
              </div>
              <div>
                <span class="body-2">IPI</span>
                <span class="body-2">{{ item.valor_ipi | money }}</span>
              </div>
              <div>
                <span class="body-2">ST</span>
                <span class="body-2">{{ item.valor_st | money }}</span>
              </div>
            </div>
          </span>
        </div>
      </div>

      <div class="total-composition">
        <OrderInfoItem
          v-if="info.total.produto"
          overline="Subtotal"
          :body="info.total.produto | money"
        />

        <OrderInfoItem
          v-if="info.total.frete"
          overline="Frete"
          :body="info.total.frete | money"
        />

        <OrderInfoItem
          v-if="info.total.seguro"
          overline="Seguro"
          :body="info.total.seguro | money"
        />

        <OrderInfoItem
          v-if="info.total.acrescimo"
          overline="Acrescimo"
          :body="info.total.acrescimo | money"
        />

        <OrderInfoItem
          v-if="info.total.desconto"
          overline="Desconto"
          :body="info.total.desconto | money"
        />

        <OrderInfoItem
          v-if="info.total.ipi"
          overline="Ipi"
          :body="info.total.ipi | money"
        />

        <OrderInfoItem
          v-if="info.total.ipi"
          overline="St"
          :body="info.total.st | money"
        />

        <h2 class="heading-2 out-space-top-medium" v-if="info.total.pedido">
          Total de {{ info.total.pedido | money }}
        </h2>
      </div>

      <div class="more-info">
        <OrderInfoItem
          v-if="info.cliente"
          overline="Cliente"
          :heading="info.cliente"
          :body="info.cliente_cnpj"
        />

        <OrderInfoItem
          v-if="info.empresa"
          overline="Empresa"
          :heading="info.empresa"
          :body="info.empresa_cnpj"
        />

        <OrderInfoItem
          v-if="Object.keys(info.endereco).length > 0"
          overline="Endereço de entrega"
          :heading="address"
        />

        <OrderInfoItem
          v-if="info.transportadora"
          overline="Transportadora"
          :heading="info.transportadora"
        />

        <OrderInfoItem
          v-if="info.vendedor"
          overline="Vendedor"
          :heading="info.vendedor"
        />

        <OrderInfoItem
          v-if="info.vendedor_ext"
          overline="Vendedor externo"
          :heading="info.vendedor_ext"
        />

        <OrderInfoItem
          v-if="info.fatura_prazo"
          overline="Prazo da fatura"
          :heading="info.fatura_prazo"
        />

        <OrderInfoItem
          v-if="info.tabela_preco"
          overline="Tabela de preço"
          :heading="info.tabela_preco"
        />

        <OrderInfoItem
          v-if="info.usuario"
          overline="Usuário"
          :heading="info.usuario"
        />

        <OrderInfoItem
          v-if="info.tipo_pedido"
          overline="Tipo de pedido"
          :heading="info.tipo_pedido"
        />
      </div>

      <div v-if="info.entrega">
        <div v-if="info.entrega.length > 0">
          <h3 class="heading-2 out-space-top-large">Entregas</h3>
          <div
            :class="['more-info']"
            v-for="(item, key) in info.entrega"
            :key="key"
          >
            <div>
              <span class="delivery-title">Tipo de entrega</span>
              <h3 class="delivery-subtitle">
                {{ item.tipo_entrega_desc }}
              </h3>
              <span class="deliveryText">
                Nº de envio: {{ item.nro_envio }}
              </span>
              <div
                v-if="item.localizacao_latitude && item.localizacao_longitude"
              >
                <a
                  :href="
                    'https://www.google.com/maps/search/?api=1&query=' +
                      item.localizacao_latitude +
                      ',' +
                      item.localizacao_longitude
                  "
                  target="_blank"
                  class="btn"
                >
                  Local da entrega
                </a>
              </div>
            </div>
            <div>
              <span class="delivery-title"
                >Responsável pela retirada / recebimento
              </span>
              <h3 class="delivery-subtitle">
                {{ item.nome_retirada }}
              </h3>
              <div v-if="item.assinatura">
                <img
                  style="height:48px;"
                  :src="'data:image/jpeg;base64,' + item.assinatura"
                />
              </div>
              <span class="deliveryText">{{ item.dateTime }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="loading" v-else>
      <div class="bouncingLoader">
        <div></div>
      </div>
    </div>
    <p class="copy body-2">
      © Desenvolvido por
      <a href="http://www.futurasistemas.com.br/">
        <img src="../assets/logo-16.png" alt="" width="16" height="16" />
        Futura Sistemas
      </a>
    </p>
  </div>
</template>

<script>
import axios from 'axios';
import OrderInfoItem from './OrderInfoItem.vue';
import moment from 'moment';

export default {
  name: 'OrderInfo',
  components: {
    OrderInfoItem,
  },
  methods: {
    handleSnackbar(index) {
      this.showSnackbar = this.showSnackbar === index ? null : index;
    },
  },
  data() {
    return {
      info: {},
      showSnackbar: null,
      paramURL: '',
    };
  },
  created() {
    const THIS = this;
    THIS.paramURL = window.location.search;
    axios
      .get(`http://ceo2.futurasistemas.com.br/webservice/consultapedido${THIS.paramURL}`)
      .then(({ data }) => {
        function getDateTime(dateTime) {
          const time = dateTime.substring(dateTime.indexOf(' ')).trim();
          dateTime = dateTime.replace(time, '');
          const date = getDate(dateTime);

          return { date, time, dateTime: `${date} ${time}` };
        }

        function getDate(dateTime) {
          const date = moment(dateTime).format('DD/MM/YYYY');

          return date;
        }

        if (Object.keys(data).length) {
          const INFO = data;

          if (INFO.entrega) {
            INFO.entrega = INFO.entrega.map(item => ({
              ...item,
              ...getDateTime(item.data_hora_entrega),
            }));
          }

          INFO.data_emissao = getDate(INFO.data_emissao);

          if (INFO.acompanhamento) {
            INFO.acompanhamento = INFO.acompanhamento
              .filter(item => item.tipo_descr)
              .map(item => {
                if (!item.data_hora) {
                  return item;
                }

                return { ...item, ...getDateTime(item.data_hora) };
              });
          }

          THIS.info = INFO;
        } else {
          THIS.info = {
            not_found: true,
          };
        }
      })
      .catch(function(error) {
        console.log(error);
        THIS.info = {
          not_found: true,
        };
      });
  },
  computed: {
    address() {
      let address =
        this.info.endereco.tipo +
        this.info.endereco.logradouro +
        ', ' +
        this.info.endereco.numero +
        this.info.endereco.bairro +
        ', ' +
        this.info.endereco.cidade +
        ' - ' +
        this.info.endereco.uf +
        this.info.endereco.pais +
        ' - ' +
        this.info.endereco.cep;

      return address;
    },
  },
  filters: {
    money(value) {
      if (!value) return '';

      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(value);
    },
  },
};
</script>

<style src="../assets/typography.css"></style>

<style scoped>
.copy {
  text-align: center;
  padding: 32px 0;
}
.copy a {
  text-decoration: none;
  color: #337ab7;
  font-weight: 500;
}
.copy img {
  display: inline-block;
  vertical-align: middle;
}
.card {
  max-width: 716px;
  width: 100%;
  margin: 32px auto;
  padding: 48px;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
  color: rgba(0, 0, 0, 0.87);
}
.not-found {
  text-align: center;
  width: calc(100% - 2rem);
}
.visible-block {
  display: block;
}
.btn {
  display: inline-block;
  padding: 10px 18px;
  background: #ffffff;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: #337ab7;
  text-decoration: none;
  transition-duration: 0.15s;
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-right: 8px;
  margin-top: 8px;
}
.btn:hover {
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
}

@media (max-width: 732px) {
  .card:not(.not-found) {
    padding: 16px;
    box-shadow: none;
    margin: 0;
  }
  .table {
    overflow: auto;
  }
}

.side-dish {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 40px;
  margin-bottom: 40px;
}
.side-dish-item {
  flex-grow: 1;
  text-align: center;
  position: relative;
  margin-top: 24px;
}
.side-dish-item::after {
  content: '';
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #666666;
  top: -24px;
  right: 50%;
  transform: translateX(50%);
}
.side-dish-item::before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background: rgba(0, 0, 0, 0.12);
  top: -16.5px;
  right: 0;
}
.side-dish-item:first-child::before {
  width: 50%;
}
.side-dish-item:last-child::before {
  width: 50%;
  transform: translateX(-100%);
}
.side-dish-item.complete::before,
.side-dish-item.complete::after {
  background: #2e7d32;
}
.side-dish-item.active::before,
.side-dish-item.active::after {
  background: #1e7cad;
}
.side-dish-item.error::before,
.side-dish-item.error::after {
  background: #dc3545;
}
.snackbar-mask {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
.snackbar {
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  width: 166px;
  background: #202020;
  position: absolute;
  transform: translateX(calc(-100% + 24px));
}
.snackbar > div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.snackbar > div:first-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  padding-bottom: 8px;
  margin-bottom: 8px;
}
.snackbar > div:first-child span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.snackbar .body-2 {
  color: #ffffff;
}
@media (max-width: 732px) {
  .snackbar {
    transform: translateX(-50%);
    left: 50%;
    bottom: 32px;
    width: calc(100% - 32px);
    max-width: 240px;
    position: fixed;
  }
}

.table {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.table-header,
.table-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  width: 618px;
  min-height: 56px;
  align-items: center;
  padding: 8px;
}
.table-row:last-child {
  border-bottom: 0;
}
.table-header > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
}
.table-header > span,
.table-row > span {
  padding: 0 8px;
}
.table-header > span:nth-child(1),
.table-row > span:nth-child(1) {
  width: 128px;
  min-width: 128px;
  flex-basis: 128px;
}
.table-header > span:nth-child(2),
.table-row > span:nth-child(2) {
  width: 186px;
  min-width: 186px;
  flex-basis: 186px;
}
.table-header > span:nth-child(3),
.table-row > span:nth-child(3) {
  text-align: right;
  width: 56px;
  min-width: 56px;
  flex-basis: 56px;
}
.table-header > span:nth-child(4),
.table-header > span:nth-child(5),
.table-row > span:nth-child(4),
.table-row > span:nth-child(5) {
  text-align: right;
  width: 96px;
  min-width: 96px;
  flex-basis: 96px;
}
.total-composition {
  display: flex;
  flex-diraction: row;
  flex-wrap: wrap;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.total-composition > div {
  flex-grow: 0;
  margin-top: 16px;
}
.total-composition > div:not(:last-child) {
  margin-right: 16px;
}
.more-info {
  padding-top: 8px;
}
@media (min-width: 720px) {
  .more-info {
    display: grid;
    grid-column-gap: 24px;
    grid-template-columns: repeat(2, 1fr);
  }
}
.more-info > div {
  padding: 16px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.out-space-top-x-small {
  margin-top: 4px;
}
.out-space-top-small {
  margin-top: 8px;
}
.out-space-top-medium {
  margin-top: 16px;
}
.out-space-top-large {
  margin-top: 24px;
}

.loading {
  height: 100vh;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: center;
}
@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translate3d(0, -16px, 0);
  }
}

.bouncingLoader > div,
.bouncingLoader:before,
.bouncingLoader:after {
  display: inline-block;
  width: 13px;
  height: 13px;
  background: #5778f3;
  border-radius: 50%;
  animation: bouncing-loader 0.6s infinite alternate;
}
.bouncingLoader > div,
.bouncingLoader:before,
.bouncingLoader:after {
  content: ' ';
}
.bouncingLoader > div {
  margin: 0 5px;
}
.bouncingLoader > div {
  animation-delay: 0.2s;
}
.bouncingLoader:after {
  animation-delay: 0.4s;
}

.delivery-title {
  display: block;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 4px;
}
.delivery-subtitle {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 4px;
}
.deliveryText {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.6);
}
</style>
